import { BaseResponse, C6BaseResponse, C6PageRequest, C6PageResponse, PageRequest } from '@/types/API'
import request, { c6Request } from '@/utils/request'

// /** 获取银行流水列表 */
// export function getBankTraderFlowList(params: PageRequest<BankTraderFlowQueryParams>) {
//   return request.get<BaseResponse>('verify-centre/bank-trader-flow-list', { params })
// }

/** 获取银行流水列表 */
export function getBankTraderFlowList(params: C6PageRequest<BankTraderFlowQueryParams>) {
  return c6Request.get<C6BaseResponse<C6PageResponse>>('backstage/payretrieve/getBankTraderPage', { params })
}

// /** 获取旋切机主下单列表 */
// export function getXqjzOrderList(params: PageRequest<XqjzOrderListQueryParams>) {
//   return request.get<BaseResponse>('verify-centre/xqjz-order-list', { params })
// }

/** 获取旋切机主下单列表 */
export function getXqjzOrderList(params: C6PageRequest<XqjzOrderListQueryParams>) {
  return c6Request.get<C6BaseResponse<C6PageResponse>>('backstage/payretrieve/getRetrieveXqjzOrder', { params })
}

// /** 财务选择流水对应的货单 */
// export function chooseOrderFlow(traderId: number, orderId: number) {
//   return request.post<BaseResponse>(`verify-centre/choose-flow-order/${traderId}/${orderId}`)
// }

/** 财务选择流水对应的货单 */
export function chooseOrderFlow(traderId: number, orderId: number) {
  return c6Request.post<C6BaseResponse<void>>(`backstage/payretrieve/bindXqjzOrderToBtf`, { traderId, orderId })
}

// /** 财务介入，线下退回 */
// export function returnOffline(orderId: number) {
//   return request.post<BaseResponse>(`verify-centre/return-offline/${orderId}`)
// }

/** 财务介入，线下退回 */
export function returnOffline(orderId: number) {
  return c6Request.post<C6BaseResponse<void>>(`backstage/payretrieve/xqjzOrderReturnOffline`, orderId, {
    headers: {"Content-Type": "application/json"}
  })
}

// /** 财务介入，开票并支付 */
// export function continuePay(orderId: number) {
//   return request.post<BaseResponse>(`verify-centre/continue-pay/${orderId}`)
// }

/** 财务介入，开票并支付 */
export function continuePay(orderId: number) {
  return c6Request.post<C6BaseResponse<void>>(`backstage/payretrieve/xqjzOrderContinuePay`, orderId, {
    headers: {"Content-Type": "application/json"}
  })
}

export interface BankTraderFlowQueryParams {
  traderTimeStart: Date,
  traderTimeEnd: Date,
  accountNumber?: string,
  accountName?: string,
  type?: number,
  isInAccount?: number,
}

export interface XqjzOrderListQueryParams {
  xqjzName?: string,
  orderCode?: string,
  createTimeStart?: Date,
  createTimeEnd?: Date,
}