
import { Buttons, Inputs, TableColumns } from "@/types/BasicComponent";
import { formatTime } from "@/utils/common";
import { defineComponent, reactive, ref, toRef, watch } from "vue";
import BasicTableDialog from '@/components/BasicPageLayout/BasicTableDialog.vue'
import BasicTable from "@/components/BasicPageLayout/BasicTable.vue";
import usePagination from "@/utils/composables/usePagination";
import { chooseOrderFlow, continuePay, getXqjzOrderList, returnOffline, XqjzOrderListQueryParams } from '@/api/xqjzorder'
import dayjs from "dayjs";
import { ElMessage, ElMessageBox } from "element-plus";

export default defineComponent({
  components: {
    BasicTableDialog,
    BasicTable
  },
  emits: ['on-success'],
  setup(props, {emit}) {
    const modalShow = ref(false)
    const pagination = usePagination()

    const title = ref('')
    const emptyTitle = ref('处理完毕')
    const traderId = ref(-1)
    const orderId = ref(-1)
    const status = ref(-1)
    const step = ref(0)
    const searchData = reactive<XqjzOrderListQueryParams>({
      xqjzName: '',
      orderCode: '',
      createTimeStart: dayjs().subtract(1, 'months').toDate(),
      createTimeEnd: dayjs().add(1, 'days').toDate()
    })
    const selectedAction = ref(1)

    watch(step, val => {
      if (val == 0) title.value = '选择订单'
      if (val == 1) title.value = '选择处理方式'
      if (val == 2) title.value = '处理完毕'
    }, {immediate: true})

    const show = (tdid: number, odId: number, flowStatus: number) => {
      step.value = 0
      modalShow.value = true
      traderId.value = tdid
      orderId.value = odId
      status.value = flowStatus
      if (odId != null) {
        step.value = 1
      }
      if (flowStatus == 3) {
        step.value = 2
        emptyTitle.value = '订单已作废'
      }
      if (flowStatus == 4) {
        step.value = 2
        emptyTitle.value = '订单已支付完毕'
      }
      if (step.value == 0) getTableData()
    }

    const getTableData = async () => {
      const respData = await getXqjzOrderList(Object.assign({ pageNo: pagination.currentPage, pageSize: pagination.pageSize }, searchData))
      tableData.value = respData.data.data.records
      pagination.total = respData.data.data.total
      tableData.value.forEach((td, index) => td.index = pagination.pageOffset + index)
    }

    pagination.setCallback(getTableData)

    const exportRef = ref()

    const onSelectOrder = async (odId: number) => {
      try {
        await ElMessageBox.confirm('是否确认将该订单与流水绑定？', '操作', {
          type: 'warning',
        })

        await chooseOrderFlow(traderId.value, odId)
        orderId.value = odId
        ElMessage.success('绑定成功')
        step.value = 1
      } catch(e) {
        console.error(e)
      }
    }

    const onConfirmHandle = async () => {

      try {
        await ElMessageBox.confirm('是否确认执行此操作？此操作将不可逆', '操作', {
          type: 'warning',
        })

        console.log(selectedAction.value + 'x');

        let handleFunc: Function | undefined
        if (selectedAction.value === 1) handleFunc = async () => await returnOffline(orderId.value)
        else if (selectedAction.value === 2) handleFunc = async () => await continuePay(orderId.value)
        else handleFunc = () => Promise.resolve()
        await handleFunc()
        ElMessage.success('处理成功')
        step.value = 2
      } catch(e) {
        console.error(e)
      }
    }

    const searchInputs = reactive<Inputs>([
      {
        type: 'input',
        label: '货单号',
        model: toRef(searchData, 'orderCode'),
      },
      {
        type: 'group',
        divider: '-',
        label: '日期',
        inputs: [
          {
            type: 'date',
            placeholder: '开始日期',
            model: toRef(searchData, 'createTimeStart')
          }, {
            type: 'date',
            placeholder: '结束日期',
            model: toRef(searchData, 'createTimeEnd')
          }
        ]
      }
    ])

    const searchButtons = reactive<Buttons>([
      {
        label: '查询',
        icon: 'el-icon-search',
        onClick: getTableData
      }
    ])

    const tableData = ref<AnyArray>([])
    const tableColumns = reactive<TableColumns>([
      {
        prop: 'index',
        label: '序号',
        width: '60px',
        align: 'center',
      }, {
        prop: 'orderCode',
        label: '货单号',
        minWidth: '150px',
        align: 'center',
      }, {
        prop: 'xqjzName',
        label: '旋切机主',
        minWidth: '150px',
        align: 'center',
      }, {
        prop: 'createdtime',
        label: '时间',
        minWidth: '150px',
        type: 'render',
        align: 'center',
        render: scope => formatTime(scope.row.createdtime)
      }, {
        prop: 'totalprice',
        label: '订单总额',
        minWidth: '200px',
        align: 'center',
      }, {
        prop: '',
        label: '操作',
        type: 'button',
        align: 'center',
        buttons: [
          { label: '选择', onClick: scope => onSelectOrder(scope.row.orderid) }
        ]
      }
    ])

    return {
      show,
      onConfirmHandle,
      status,
      title,
      step,
      pagination,
      modalShow,
      tableData,
      searchInputs,
      searchButtons,
      tableColumns,
      selectedAction,
      emptyTitle
    }
  }
})
