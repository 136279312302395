
import BasicTablePage from '@/components/BasicPageLayout/BasicTablePage.vue'
import { defineComponent, onMounted, reactive, ref, toRef } from 'vue'
import usePagination from '@/utils/composables/usePagination'
import { UserRole } from '@/types/Role'
import { Buttons, Inputs, TableColumns } from '@/types/BasicComponent'
import { formatTime, formatDate, getTableColumnsSummary } from '@/utils/common'
import BasicTablePrintAndExport from '@/components/BasicPageLayout/BasicTablePrintAndExport.vue'
import { useRouter } from 'vue-router'
import { BankTraderFlowQueryParams, getBankTraderFlowList } from '@/api/xqjzorder'
import dayjs from 'dayjs'
import FlowOrderSelect from '@/views/pay/BankFlowOrderSelect.vue'

export default defineComponent({
  components: {
    BasicTablePage,
    BasicTablePrintAndExport,
    FlowOrderSelect
  },
  setup() {
    const router = useRouter()
    const pagination = usePagination()
    const searchData = reactive<BankTraderFlowQueryParams>({
      traderTimeStart: dayjs().subtract(1, 'months').toDate(),
      traderTimeEnd: dayjs().add(1, 'days').toDate(),
      accountNumber: '',
      accountName: '',
      type: 1,
      isInAccount: undefined
    })
    const tableData = ref<AnyArray>([])
    const selectCount = ref(0)

    const exportRef = ref()
    const flowOrderSelectRef = ref()

    const getTableData = async () => {
      const resp = await getBankTraderFlowList(Object.assign({ pageNo: pagination.currentPage, pageSize: pagination.pageSize }, searchData))
      const respData = resp.data.data.records

      tableData.value = respData
      tableData.value.forEach((td, idx) => {
        td.index = pagination.pageOffset + idx,
        td.isInAccount = td.recharge_id == null ? 2 : 1
      })

      pagination.total = resp.data.data.total
    }

    pagination.setCallback(getTableData)

    onMounted(() => {
      getTableData()
    })

    const searchInputs = reactive<Inputs>([
      {
        label: '户名',
        type: 'input',
        placeholder: '请输入户名',
        model: toRef(searchData, 'accountName'),
        width: '200px',
        clearable: true
      }, {
        label: '卡号',
        type: 'input',
        placeholder: '请输入卡号',
        model: toRef(searchData, 'accountNumber'),
        width: '150px',
        clearable: true
      }, 
      // {
      //   label: '胶合板厂是否到账',
      //   type: 'select',
      //   placeholder: '请选择是否到账',
      //   model: toRef(searchData, 'IsInAccount'),
      //   width: '150px',
      //   clearable: true,
      //   options: [
      //     { label: '是', value: 1 },
      //     { label: '否', value: 2 }
      //   ]
      // }, 
      {
        label: '收支类型',
        type: 'select',
        placeholder: '请选择收支类型',
        model: toRef(searchData, 'type'),
        width: '150px',
        clearable: true,
        options: [
          { label: '收入', value: 1 },
          { label: '支出', value: 2 }
        ]
      }, {
        label: '交易时间',
        type: 'group',
        divider: '至',
        inputs: [
          {
            type: 'date',
            model: toRef(searchData, 'traderTimeStart'),
            clearable: false,
            placeholder: '开始日期',
            width: '150px',
          }, {
            type: 'date',
            model: toRef(searchData, 'traderTimeEnd'),
            clearable: false,
            placeholder: '结束日期',
            width: '150px'
          }
        ]
      }
    ])

    const searchButtons = reactive<Buttons>([
      {
        label: '查询',
        icon: 'el-icon-search',
        onClick: getTableData
      }, {
        label: '导出',
        icon: 'el-icon-download',
        onClick: async () => {
          exportRef.value.doExport()
        }
      }
    ])

    const tableColumns = reactive<TableColumns>([
      {
        prop: 'index',
        label: '序号',
        width: '70px',
        align: 'center'
      }, {
        prop: 'requestCode',
        label: '平台流水号',
        minWidth: '150px',
        preventFormat: true
      }, {
        prop: 'btfAccountName',
        label: '户名',
        minWidth: '150px',
        preventFormat: true
      }, {
        prop: 'btfAccountNumber',
        label: '卡号',
        minWidth: '150px',
        preventFormat: true
      }, {
        prop: 'btfRcvamt',
        label: '收入',
        minWidth: '120px',
        preventFormat: true
      }, {
        prop: 'btfPayamt',
        label: '支出',
        minWidth: '120px',
      }, {
        prop: 'flowStatus',
        label: '状态',
        minWidth: '130px',
        type: 'status',
        align: 'center',
        statusType: [
          { label: '未到账', value: 1, type: 'warning' },
          { label: '已到账', value: 2, type: 'primary' },
          { label: '订单已作废', value: 3, type: 'info' },
          { label: '订单已支付', value: 4, type: 'success' }
        ]
      }, {
        prop: 'btfType',
        label: '类型',
        minWidth: '80px',
        type: 'status',
        align: 'center',
        statusType: [
          { label: '收入', value: 1, type: 'success' },
          { label: '支出', value: 2, type: 'warning' }
        ]
      }, {
        prop: 'orderCode',
        label: '关联订单号',
        minWidth: '150px',
      }, {
        prop: 'bankAccountNumber',
        label: '平台账户',
        minWidth: '175px',
      }, {
        prop: 'bankPlatformAccountName',
        label: '平台账户名',
        minWidth: '200px',
      }, {
        prop: 'btfTraderTime',
        label: '交易时间',
        minWidth: '150px',
        type: 'render',
        render: scope => formatTime(scope.row.btfTraderTime)
      }, {
        prop: 'btfDesc',
        label: '附言',
        minWidth: '150px',
      }, {
        prop: '',
        label: '操作',
        type: 'button',
        align: 'center',
        fixed: 'right',
        buttons: [
          {
            label: '处理',
            disabled: scope => {
              const row = scope.row
              return row.btfType != 1
            },
            onClick: scope => {
              flowOrderSelectRef.value.show(scope.row.id, scope.row.orderId, scope.row.flowStatus)
            }
          }
        ]
      }
    ])
    
    const mySummaryMethod = (scope: AnyObject) => {
      let props = ['btfRcvamt', 'btfPayamt']
      return getTableColumnsSummary(props, scope.columns, scope.data)
    }
    
    return {
      getTableData,
      formatTime,
      formatDate,
      exportRef,
      flowOrderSelectRef,
      searchInputs,
      searchData,
      searchButtons,
      tableColumns,
      pagination,
      tableData,
      UserRole,
      selectCount,
      mySummaryMethod
    }
  }
})
