
import { computed, onActivated, onMounted, ref, toRefs, watch, nextTick, defineComponent, PropType, Prop } from 'vue'
import useRole from '@/utils/composables/useRole';
import BasicTable from '@/components/BasicPageLayout/BasicTable.vue'
import BasicInputGroup from '@/components/BasicPageLayout/BasicInputGroup.vue'
import dragable from '@/utils/dragable'
import { Buttons, Inputs, TableColumns, RenderFunc, InputConfig } from '@/types/BasicComponent'
import { authFilter, renderButton } from './utils'
import { Pagination } from '@/types/Pagination';
let counter = 0
export default defineComponent({
  components: {
    BasicTable,
    BasicInputGroup
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    searchInputs: {
      type: Array as PropType<Inputs>,
      default: []
    },
    searchButtons: {
      type: Array as PropType<Buttons>,
      default: []
    },
    tableColumns: {
      type: Array as PropType<TableColumns>,
      default: []
    },
    tableData: {
      type: Array,
      default: []
    },
    tableLoading: {
      type: Boolean
    },
    pagination: {
      type: Object as PropType<Pagination>
    },
    footerButtons: {
      type: Array as PropType<Buttons>,
      default: []
    },
    modelValue: {
      type: Boolean
    },
    onClose: {
      type: Function
    },
    dragable: {
      type: Boolean,
      default: true
    },
    dialogWidth: {
      type: [String, Number],
      default: '1200px'
    },
    tableRowClassName: {
      type: [Function, String],
      default: ''
    },
    summaryMethod: {
      type: Function,
    },
    tableHeight: {
      type: Number,
      default: Math.round(window.innerHeight * 0.5 - 20) //减去一个调整值，margin，border 之类高度
    }
  },
  emits: ['update:modelValue'],
  setup(props, {emit, slots}) {
    counter ++
    const tableContainerRef = ref(null)
    const visible = ref(props.modelValue)

    watch(() => props.modelValue, () => visible.value = props.modelValue, {flush: 'post'})
    watch(visible, () => emit('update:modelValue', visible.value))

    watch(visible, () => {
      if (!props.pagination) return
      // 有时候pageSize不显示
      const pagination = props.pagination as Pagination
      const t = pagination.pageSize
      pagination.pageSize = -1
      nextTick(() => pagination.pageSize = t)
    })

    if (props.dragable) {
      onMounted(() => {
        const dragableDom = document.querySelector(`.__basic-table-dialog-${counter}`) as HTMLElement
        const dragableArea = dragableDom.querySelector('.el-dialog__header') as HTMLElement
        dragable(dragableDom, dragableArea)
      })
    }

    const validTableColumns = computed(() => authFilter(props.tableColumns))
    const validSearchInputs = computed(() => authFilter(props.searchInputs))
    const validSearchButtons = computed(() => authFilter(props.searchButtons))
    // @ts-ignore
    const inputGroupRender: RenderFunc<[JSX.Element, InputConfig]> = (itemRender, itemConfig) => (
      <div class="filter-item">
        {itemConfig.label && <span>{itemConfig.label}</span>}
        {itemRender}
      </div>
    )

    return () => (
      <el-dialog
        v-model={visible.value}
        onClose={props.onClose}
        custom-class={`dialog-container __basic-table-dialog-${counter}`}
        width={props.dialogWidth}
        // close-on-click-modal={false}
        v-slots={{
          title: () => {
            return (
              <div>
                <span class="dialog-title">{props.title}</span>
                <span class="dialog-sub-title">{props.subTitle}</span>
              </div>
            )
          },
          default: () => {
            return (
              <div>
                {slots.head?.()}
                <div class="filter-container dialog-section-1st filter-container-flex">
                  <div>
                    {slots.searchInput?
                      slots.searchInput():
                      (
                        <basic-input-group
                          inputs={validSearchInputs.value}
                          render={inputGroupRender}
                        ></basic-input-group>
                      )
                    }
                  </div>
                  {
                    slots.searchButton? (
                      <div style="display: flex; justify-content: flex-end">
                        {slots.searchButton()}
                      </div>
                    ):
                    validSearchButtons.value.length > 0 && ( 
                      <div style="display: flex; justify-content: flex-end">
                        <div class="filter-item">
                          {props.searchButtons.map(buttonConfig => renderButton(buttonConfig))}
                        </div>
                      </div>
                    )
                  }
                </div>
                <div ref={tableContainerRef}>
                  {slots.table? slots.table()
                    : (
                      <basic-table
                        tableColumns={validTableColumns.value}
                        tableData={props.tableData}
                        tableHeight={props.tableHeight}
                        tableLoading={props.tableLoading}
                        rowClassName={props.tableRowClassName}
                        summaryMethod={props.summaryMethod}
                      >
                      </basic-table>
                    )
                  }
                  {props.pagination && (
                    <el-pagination
                      onSizeChange={props.pagination.onSizeChange}
                      onCurrentChange={props.pagination.onCurrentChange}
                      currentPage={props.pagination.currentPage}
                      pageSizes={props.pagination.pageSizes}
                      pageSize={props.pagination.pageSize}
                      layout={props.pagination.layout}
                      total={props.pagination.total}
                    ></el-pagination>
                  )}
                </div>
              </div>
            )
          },
          footer: () => {
            return (
              <div>
                {props.footerButtons.map(buttonConfig => renderButton(buttonConfig))}
              </div>
            )
          }
        }}
      ></el-dialog>
    )
  }
})
