import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"margin-top":"20px"} }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { style: {"display":"flex","justify-content":"center","flex-direction":"column","align-items":"center"} }
const _hoisted_4 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_step = _resolveComponent("el-step")!
  const _component_el_steps = _resolveComponent("el-steps")!
  const _component_BasicTable = _resolveComponent("BasicTable")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_empty = _resolveComponent("el-empty")!
  const _component_BasicTableDialog = _resolveComponent("BasicTableDialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BasicTableDialog, {
      title: _ctx.title,
      modelValue: _ctx.modalShow,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modalShow) = $event)),
      searchInputs: _ctx.step != 0 ? [] : _ctx.searchInputs,
      searchButtons: _ctx.step != 0 ? [] : _ctx.searchButtons,
      pagination: _ctx.step != 0 ? undefined : _ctx.pagination,
      width: "1000px",
      top: "10vh"
    }, {
      head: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_el_steps, {
            space: 200,
            active: _ctx.step,
            simple: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_step, {
                title: "选择订单",
                icon: "el-icon-s-order"
              }),
              _createVNode(_component_el_step, {
                title: "选择处理方式",
                icon: "el-icon-s-operation"
              }),
              _createVNode(_component_el_step, {
                title: "处理完毕",
                icon: "el-icon-check"
              })
            ]),
            _: 1
          }, 8, ["active"])
        ])
      ]),
      table: _withCtx(() => [
        (_ctx.step == 0)
          ? (_openBlock(), _createBlock(_component_BasicTable, {
              key: 0,
              tableColumns: _ctx.tableColumns,
              tableData: _ctx.tableData
            }, null, 8, ["tableColumns", "tableData"]))
          : (_ctx.step == 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_el_tooltip, {
                      class: "item",
                      effect: "dark",
                      content: "此操作会扣减胶合板厂的余额，扣减的金额与充值金额相等（余额不足将扣为负数），并将订单作废",
                      placement: "right"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_radio, {
                          label: 1,
                          modelValue: _ctx.selectedAction,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedAction) = $event))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("线下退回")
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", null, [
                    _createVNode(_component_el_tooltip, {
                      class: "item",
                      effect: "dark",
                      content: "此操作将会从胶合板厂余额中扣除此单应付的订单与开票费用，如果胶合板厂余额不足操作将会失败",
                      placement: "right"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_radio, {
                          label: 2,
                          modelValue: _ctx.selectedAction,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedAction) = $event))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("自动支付")
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", null, [
                    _createVNode(_component_el_tooltip, {
                      class: "item",
                      effect: "dark",
                      content: "此操作将不作额外处理，将充值的金额留在胶合板厂余额中",
                      placement: "right"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_radio, {
                          label: 3,
                          modelValue: _ctx.selectedAction,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedAction) = $event))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("进入余额")
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _createVNode(_component_el_button, {
                    style: {"margin-top":"40px"},
                    type: "primary",
                    onClick: _ctx.onConfirmHandle
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" 确定 ")
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_el_empty, { description: _ctx.emptyTitle }, null, 8, ["description"])
              ]))
      ]),
      _: 1
    }, 8, ["title", "modelValue", "searchInputs", "searchButtons", "pagination"])
  ]))
}